import * as React from 'react';
import type {HeadFC, PageProps} from 'gatsby';
import {graphql} from 'gatsby';

import './global.css';
import {CMSData} from '../@types/CMSData';
import ZvanMainPage from '../components/ZvanMainPage';

const IndexPage = ({data}: PageProps<CMSData>) => {
  return <ZvanMainPage data={data} />;
};

export default IndexPage;

export const Head: HeadFC = () => <title>Žvan - Izdelava in predelava unikatnih vozil</title>;

export const query = graphql`
  query MyQuery {
    allProject {
      nodes {
        title
        description
        images
        order
        isFeatured
        featuredImage
      }
    }
    allContactInfo(filter: {id: {eq: "main-contact-info"}}) {
      nodes {
        address
        city
        email
        facebookUrl
        instagramUrl
        phone
        workingHours
      }
    }
  }
`;
